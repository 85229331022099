<template>
  <div class="div-block-7 article-content-subpage" style="max-width: 500px">
    <div class="div-block-14" style="padding-bottom: 10px">
      <h1 class="heading-link heading-subpage">Bereich für MTB-Guides</h1>
      <p>
        Dieser Bereich ist ausschließlich für MTB-Guides gedacht und daher mit
        einem Passwort geschützt. Bitte trage das Passwort ein, um fortzufahren.
      </p>
    </div>
    <div class="block-article">
      <div class="block-article-content">
        <form :class="{ loading }" class="form" v-on:submit.prevent="login">
          <div>
            <label class="text-meta">Passwort</label>
            <input
              class="w-input"
              v-model="credentials.password"
              placeholder="********"
              type="password"
              id="password"
              name="password"
              autocomplete="current-password"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="8"
              required
              @input="error = null"
            />
          </div>
          <div v-if="error" class="w-form-fail">
            <strong>Anmeldung fehlgeschlagen</strong><br />
            <span>{{ error }}</span>
          </div>
          <button
            :class="{ loading }"
            :disabled="loading"
            class="w-button"
            style="
              width: 100%;
              margin-top: 10px;
              font-weight: 600;
              height: 50px;
            "
          >
            Absenden
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guide",
  metaInfo: {
    title: "MTB-Guides",
  },
  data() {
    return {
      loading: false,
      error: null,
      credentials: {
        password: null,
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.error = null;

      if (
        !this.credentials.password ||
        this.credentials.password != "mtbguide2020"
      ) {
        this.error =
          "Das eingetragene Passwort ist falsch. Bitte prüfe deine Angaben und probiere es erneut!";
        this.loading = false;
        this.credentials.password = null;
      } else if (
        this.credentials.password &&
        this.credentials.password === "mtbguide2020"
      ) {
        this.loading = false;
        this.credentials.password = null;
        window.location =
          "https://drive.google.com/open?id=0B_LAw6hfBobLelUxeGNVV0dYWFU";
      }
    },
  },
};
</script>
